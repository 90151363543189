/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";

const { VUE_APP_WF_API_URL, VUE_APP_WF_WS_URL, NODE_ENV } = process.env;

// ActionCable
import ActionCable from "actioncable";
Vue.prototype.$cable = ActionCable.createConsumer(VUE_APP_WF_WS_URL);

// Vue Router
import router from "./router";

// Vuesax Component Framework
import Vuesax from "vuesax";
import BootstrapVue from "bootstrap-vue";
import jwtDecode from "jwt-decode";
import Axios from "axios";
import DateRangePicker from "@gravitano/vue-date-range-picker";

import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(Vuesax);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(DateRangePicker);

window.$ = require("jquery");
window.JQuery = require("jquery");

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");
  if (to.name != "signin" && !localStorage.getItem("token")) {
    next("/signin");
    return;
  } else if (to.name != "signin" && token) {
    let payload = jwtDecode(token);
    if (Date.now() / 1000 > payload.exp) {
      next("/signin");
      return;
    }
  }

  next();
});

Vue.prototype.host = VUE_APP_WF_API_URL;
Vue.prototype.$isProd = NODE_ENV == "production";
Vue.prototype.$user = JSON.parse(localStorage.getItem("user"));
Vue.prototype.$http = Axios;
Axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ((error.response || {}).status === 401)
      router.push({
        name: "signin",
      });
    return Promise.reject(error);
  }
);

const verticalLinePlugin = {
  getLinePosition: function (chart, pointIndex) {
    const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
    const data = meta.data;
    try {
      return data[pointIndex]._model.x;
    } catch (ex) {
      // TODO: Fix this
    }
  },
  renderVerticalLine: function (chartInstance, pointIndex, title) {
    const lineLeftOffset = this.getLinePosition(chartInstance, pointIndex);
    const scale = chartInstance.scales["y-axis-0"];
    const context = chartInstance.chart.ctx;

    // render vertical line
    context.beginPath();
    context.strokeStyle = "#ff0000";
    context.moveTo(lineLeftOffset, scale.top + 5);
    context.lineTo(lineLeftOffset, scale.bottom);
    context.stroke();

    // write label
    context.fillStyle = "#ff0000";
    context.textAlign = "center";
    context.fillText(title, lineLeftOffset, scale.top); //(scale.bottom - scale.top) / 2 + scale.top);
  },

  afterDatasetsDraw: function (chart, _easing) {
    if (chart.config.bidsChangedAt) {
      chart.config.bidsChangedAt.forEach((pointIndex) =>
        this.renderVerticalLine(chart, pointIndex, "Bid")
      );
    }

    if (chart.config.budgetChangedAt) {
      chart.config.budgetChangedAt.forEach((pointIndex) =>
        this.renderVerticalLine(chart, pointIndex, "Budget")
      );
    }
  },
};

Chart.plugins.register(verticalLinePlugin);

Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
  components: {},
}).$mount("#app");

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vuex Store
import store from "./store/store";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

require("./assets/css/fontawesome/css/all.css");

Vue.config.productionTip = false;
