/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
  ==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import Dashboard from "./views/Dashboard.vue";
import SignIn from "./views/SignIn.vue";
import CampaignsPage from "./views/CampaignsPage";
import ArticlesPage from "./views/ArticlesPage";
import NewCampaign from "./views/NewCampaign";
import DuplicateCampaign from "./views/DuplicateCampaign.vue";
import EditCampaign from "./views/EditCampaign.vue";
import OSAndBrowser from "./views/OSAndBrowser";
import BudgetReached from "./views/BudgetReached";
import Settings from "./views/Settings.vue";
import Rules from "./views/Rules";
import NewRule from "./views/NewRule";
import Notifications from "./views/Notifications";
import ActivityLog from "./views/ActivityLog";
import MassDuplicate from "./views/MassDuplicate";
import MassGenerate from "./views/MassGenerate";
import NewPush from "./views/Push/NewPush.vue";
import PushOverview from "./views/Push/PushOverview.vue";
import PushAudiences from "./views/Push/PushAudiences.vue";
import NewAudience from "./views/Push/NewAudience.vue";
import Publishers from "./views/Admin/Publishers.vue";
import EditPublisher from "./views/Admin/EditPublisher.vue";
import Users from "./views/Admin/Users.vue";
import EditUser from "./views/Admin/EditUser.vue";
import AddAccount from "./views/Admin/AddAccount.vue";
import Library from "./views/Creatives/Library/Library.vue";
import VersionForm from "./views/Creatives/Form/VersionForm.vue";
import MonetizationPresets from "./views/Monetization/MonetizationPresets.vue";
import NewMonetizationPreset from "./views/Monetization/NewMonetizationPreset.vue";
import NewAdUnit from "./views/Settings/prebid/NewAdUnit.vue";

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "dashboard",
          component: Dashboard,
          meta: {
            pageTitle: "Dashboard",
          },
        },
        {
          path: "/campaigns",
          name: "campaigns",
          component: CampaignsPage,
          meta: {
            pageTitle: "Overview",
          },
        },
        {
          path: "/articles",
          name: "articles",
          component: ArticlesPage,
          meta: {
            pageTitle: "Articles Overview",
          },
        },
        {
          path: "/new-campaign",
          name: "new-campaign",
          component: NewCampaign,
          meta: {
            pageTitle: "New Campaign",
          },
        },
        {
          path: "/monetization-presets",
          name: "monetization-presets",
          component: MonetizationPresets,
          meta: {
            pageTitle: "Monetization Presets",
          },
        },
        {
          path: "/bi",
          beforeEnter() {
            window.open(
              "https://datastudio.google.com/u/0/reporting/521b83f9-b250-43ee-a006-4252cd74ea49/"
            );
          },
        },
        {
          path: "/os-and-browser",
          name: "os-and-browser",
          component: OSAndBrowser,
          meta: {
            pageTitle: "OS & Browser",
          },
        },
        {
          path: "/budget-reached",
          name: "budget-reached",
          component: BudgetReached,
          meta: {
            pageTitle: "Budget Reached",
          },
        },
        {
          path: "/new-monetization-preset",
          name: "new-monetization-preset",
          component: NewMonetizationPreset,
          meta: {
            pageTitle: "New Monetization Preset",
          },
        },
        {
          path: "/new-ad-unit",
          name: "new-ad-unit",
          component: NewAdUnit,
          meta: {
            pageTitle: "New Ad Unit",
          },
        },
        {
          path: "/activity-log",
          name: "activity-log",
          component: ActivityLog,
          meta: {
            pageTitle: "Activity Log",
          },
        },
        {
          path: "/admin/publishers",
          name: "admin-publishers",
          component: Publishers,
          meta: {
            pageTitle: "Publishers",
          },
        },
        {
          path: "/admin/publishers/:id/edit",
          name: "edit-publishers",
          component: EditPublisher,
          meta: {
            pageTitle: "Edit",
          },
        },
        {
          path: "/admin/users",
          name: "admin-users",
          component: Users,
          meta: {
            pageTitle: "users",
          },
        },
        {
          path: "/admin/users/:id/edit",
          name: "edit-users",
          component: EditUser,
          meta: {
            pageTitle: "Edit",
          },
        },
        {
          path: "/admin/users/new",
          name: "new-user",
          component: EditUser,
          meta: {
            pageTitle: "New User",
          },
        },
        {
          path: "/admin/publishers/:id/ad_accounts/new",
          name: "add-ad-account",
          component: AddAccount,
          meta: {
            pageTitle: "Add Ad Account",
          },
        },
        {
          path: "/admin/publishers/:id/ad_accounts/:ad_account_id/edit",
          name: "edit-ad-account",
          component: AddAccount,
          meta: {
            pageTitle: "Edit Ad Account",
          },
        },
        {
          path: "/library",
          name: "library",
          component: Library,
          meta: {
            pageTitle: "Creatives Library",
          },
        },
        {
          path: "/new-version",
          name: "new-version",
          component: VersionForm,
          meta: {
            pageTitle: "Create Version",
          },
        },
        {
          path: "/add-creatives",
          name: "add-creatives",
          component: VersionForm,
          meta: {
            pageTitle: "Add Creatives",
          },
        },
        {
          path: "/rules",
          name: "rules",
          component: Rules,
          meta: {
            pageTitle: "Rules",
          },
        },
        {
          path: "/rules/new",
          name: "rules-new",
          component: NewRule,
          meta: {
            pageTitle: "New Rule",
          },
        },
        {
          path: "/rules/:id",
          name: "rules-edit",
          component: NewRule,
          meta: {
            pageTitle: "Rule",
          },
        },
        {
          path: "/notifications",
          name: "notifications",
          component: Notifications,
          meta: {
            pageTitle: "Notifications",
          },
        },
        {
          path: "/campaigns/:id/duplicate",
          name: "DuplicateCampaign",
          component: DuplicateCampaign,
          meta: {
            pageTitle: "Duplicate Campaign",
          },
        },
        {
          path: "/campaigns/:id/mass_dup",
          name: "MassDuplicate",
          component: MassDuplicate,
          meta: {
            pageTitle: "Mass Duplicate",
          },
        },
        {
          path: "/campaigns/:id/mass_generate",
          name: "MassGenerate",
          component: MassGenerate,
          meta: {
            pageTitle: "Mass Generate",
          },
        },
        {
          path: "/campaigns/:id",
          name: "EditCampaign",
          component: EditCampaign,
          meta: {
            pageTitle: "Edit",
          },
        },
        {
          path: "/settings/:platform/:sub_menu",
          name: "Settings",
          props: true,
          component: Settings,
        },
        {
          path: "/push/new",
          name: "push-new",
          component: NewPush,
          meta: {
            pageTitle: "Push",
          },
        },
        {
          path: "/push/overview",
          name: "push-overview",
          component: PushOverview,
          meta: {
            pageTitle: "Push - Overview",
          },
        },
        {
          path: "/push/audiences",
          name: "push-audiences",
          component: PushAudiences,
          meta: {
            pageTitle: "Push - Audiences",
          },
        },
        {
          path: "/push/audiences/new",
          name: "push-audiences-new",
          component: NewAudience,
          meta: {
            pageTitle: "Create Push Audience",
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/signin",
          name: "signin",
          component: SignIn,
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
